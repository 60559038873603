import { Component } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Services
import intl from "../../services/intl";

// Components
import Container from "../Container";
import MagicLink from "../MagicLink";
import Row from "../Row";
import RitualButton from "../global/RitualButton";

// Utils
import { Color, Font, responsive } from "../../utils/style";
import metrics from "../../utils/metrics";

const CtaContainer = styled(Container).attrs({
  role: "section",
})`
  padding-top: 84px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    height: 4px;
    background-color: ${Color.ritualBlue};

    ${responsive.sm`
      left: 0;
      right: 0;
    `};
  }

  ${responsive.md`
    padding-top: 124px;
  `};
`;

const CtaWrapper = styled.div.attrs({
  className: "col-12 col-sm-10 offset-sm-1",
})`
  padding-left: 20px;
  padding-right: 20px;

  ${responsive.sm`
    padding-left: 0;
    padding-right: 0;
  `};
`;

const Content = styled.div`
  position: relative;

  height: 414px;
  padding: 24px 20px 24px 20px;
  border-radius: 8px;
  overflow: hidden;

  background-color: ${Color.ritualYellow};
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 80px;

  ${responsive.sm`
    height: 299px;
    padding: 40px;
      justify-content: flex-start;
  `};

  ${responsive.md`
    height: 399px;
    padding: 56px;
      margin-bottom: 120px;
  `};

  ${responsive.lg`
    height: 500px;
    padding: 80px;
  `};
`;

const FullClick = styled(MagicLink)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 3;
`;

const Title = styled.div`
  p {
    font-weight: 500;
    margin: 0;

    font-size: 26px;
    letter-spacing: -0.35px;
    line-height: 34px;

    ${responsive.md`
      font-size: 34px;
      letter-spacing: -0.5px;
      line-height: 40px;
    `}

    i {
      ${Font.dutch};
    }
  }

  margin-bottom: 8px;
  ${responsive.md`
    margin-bottom: 16px;
  `}
`;

const MobileImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px 12px;
  height: 240px;
  width: 100%;
  z-index: 1;
`;

const Description = styled.div`
  p {
    margin: 0;
    font-weight: 500;

    font-size: 16px;
    letter-spacing: 0px;
    line-height: 26px;

    ${responsive.md`
      font-size: 18px;
      letter-spacing: 0px;
      line-height: 28px;
    `}
  }

  margin-bottom: 32px;
  ${responsive.md`
    margin-bottom: 40px;
  `}
`;

export default class EfwCta extends Component {
  trackCTAClick() {
    let event = {
      title: "Learn More",
      location: "Clinical Study Page",
      nonInteraction: false,
    };
    metrics.track("CTA Clicked", event);
  }

  render() {
    const {
      description,
      ctaText,
      desktopImage,
      mobileImage,
      mobileBackgroundImage,
    } = this.props;
    const efwPath = "/products/essential-for-women-multivitamin";

    return (
      <CtaContainer>
        <Row>
          <CtaWrapper>
            <Content>
              <ImageWrapper>
                <FullClick className="d-block d-md-none" to={efwPath} />
                <GatsbyImage
                  className="d-none d-sm-block"
                  alt={desktopImage.description}
                  image={getImage(desktopImage)}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
                <MobileImageWrapper>
                  <GatsbyImage
                    className="d-block d-sm-none"
                    alt={mobileImage.description}
                    image={getImage(mobileImage)}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                </MobileImageWrapper>
                <GatsbyImage
                  className="d-block d-sm-none"
                  alt={mobileBackgroundImage.description}
                  image={getImage(mobileBackgroundImage)}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </ImageWrapper>
              <TextWrapper>
                <Title>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: intl.t(
                        "clinical.efw-cta.title",
                        "Essential <i>for Women</i>",
                      ),
                    }}
                  />
                </Title>
                <Description>
                  <p>{description}</p>
                </Description>
                <RitualButton
                  onClick={this.trackCTAClick.bind(this)}
                  to="/products/essential-for-women-multivitamin"
                  title="Learn More"
                  className="alt-hover"
                >
                  {ctaText}
                </RitualButton>
              </TextWrapper>
            </Content>
          </CtaWrapper>
        </Row>
      </CtaContainer>
    );
  }
}
